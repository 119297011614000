import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const StyledHeader = styled.header`
	display: flex;
	justify-content: space-between;
	padding: 30px;

	@media (max-width: 767px) {
		padding: 30px 1em;
	}
`;

export const Header = ({ title }) => {
	return (
		<StyledHeader>
			<Link to="/">{title}</Link>
			<nav>
				<Link to="about">About</Link>
			</nav>
		</StyledHeader>
	);
};
