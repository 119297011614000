import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { Seo } from '@components/seo';
import { Header } from '@components/header';
import './__base__.css';
import './global.css';

const StyledLayout = styled.div`
	margin: 0 auto;
	max-width: 720px;
	padding: 0 1em 40px;
`;

const Layout = ({
	children,
	title = false,
	description = false,
	// image = false,
	path = false,
}) => {
	const data = useStaticQuery(graphql`
		query GetSiteTitle {
			site {
				siteMetadata {
					title
				}
			}
		}
	`);

	const meta = data?.site?.siteMetadata ?? {};

	return (
		<>
			<Seo title={title} description={description} path={path} />
			<Header title={meta.title} />
			<StyledLayout>{children}</StyledLayout>
		</>
	);
};

export default Layout;
